import { defineStore } from 'pinia'
import { auth } from '/src/firebase'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { db } from '/src/firebase'
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  updateDoc,
} from 'firebase/firestore'
import { setCssVar, date } from 'quasar'
import LogRocket from 'logrocket'

LogRocket.init('auqfci/rtt3')

export const useAuthStore = defineStore('auth', {
  state: () => ({
    version: '3.2.135',
    nameSavedStatus: true,
    phoneSavedStatus: true,
    uid: '',
    organizationid: '',
    authError: '',
    user: {},
    details: {},
    authshake: null,
    authclass: '',
    signedindate: 0,
    changepassword: false,
    organization: {},
    progressFullBackup: false,
    fullBackupStatus: 'Ready to perform Full Backup...',
    fullBackupProgress: 0,
    progressFullRestore: false,
    fullRestoreStatus: 'Ready to perform Full Restore...',
    fullRestoreProgress: 0,
    restoreToOrg: {},
    restoreToDifferentOrg: false,
    restoreFileDetails: false,
    confirmClearTable: false,
  }),
  actions: {
    async init() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // console.log('user:',user)
          this.authError = ''
          this.user = user
          const docRef = doc(db, 'users', user.email)
          const docSnap = await getDoc(docRef)

          if (docSnap.exists()) {
            this.details = docSnap.data()
            this.uid = this.user.uid
            this.organizationid = this.details.organizationid
          } else {
            console.log('No such document!')
          }

          LogRocket.identify(this.uid, {
            name: this.details.name,
            email: this.details.email,
          })
          this.details.lastLoginAt = date.formatDate(
            Date.now(),
            'YYYY-MM-DD HH:mm:ss'
          )
          if (this.details.lastRoute) {
            try {
              this.router.push(this.details.lastRoute)
            } catch {
              console.log('Caught: ', this.details.lastRoute)
              this.router.push('Contacts')
            }
          } else {
            this.router.push('Contacts')
          }
          const orgRef = doc(db, 'organizations', this.organizationid)
          const orgSnap = await getDoc(orgRef)

          if (orgSnap.exists()) {
            this.organization = orgSnap.data()
          } else {
            console.log('No such document!')
          }
          if (
            'primarycolor' in this.organization &&
            this.organization.primarycolor
          ) {
            setCssVar('primary', this.organization.primarycolor)
          }
        } else {
          this.authError = ''
          this.user = {}
          this.details = {}
          this.uid = ''
          this.router.replace('/')
        }
      })
    },
    async registerUser(credentials) {
      await createUserWithEmailAndPassword(
        auth,
        credentials.username,
        credentials.password
      )
        .then((userCredential) => {
          this.authError = ''
          const user = userCredential.user
        })
        .catch((error) => {
          this.authError = error.message
          console.log('Login error: ', error.message)
        })
    },
    async loginUser(credentials) {
      await signInWithEmailAndPassword(
        auth,
        credentials.username,
        credentials.password
      )
        .then((userCredential) => {
          const user = userCredential.user
          this.authError = ''
        })
        .catch((error) => {
          this.authError = error.message
          console.log('Login error: ', error.message)
        })
    },
    async logoutUser() {
      await signOut(auth)
        // .then(() => {
        //   this.authError = ''
        // })
        .catch((error) => {
          this.authError = error.message
          console.log('logoutUser: ', error.message)
        })
    },
    async saveUserDetails() {
      this.details.lastLoginAt = date.formatDate(
        Date.now(),
        'YYYY-MM-DD HH:mm:ss'
      )
      await setDoc(doc(db, 'users', this.user.email), this.details)
      this.nameSavedStatus = true
      this.phoneSavedStatus = true
    },
    async sendForgotPasswordEmail(email) {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          this.authError =
            'INFO: If ' +
            email +
            ' is a valid account, a password reset email will be sent.'
        })
        .catch((error) => {
          this.authError = error.message
        })
    },
    async changelog(changed) {
      // console.log('changed', changed)
      try {
        await addDoc(
          collection(db, 'organizations', this.organizationid, 'changelog'),
          changed
        )
      } catch (error) {
        console.log('error:', error)
      }
    },
    async updatePassword(currentpassword, password) {
      const auth = getAuth()
      await updatePassword(auth.currentUser, password)
        .then(() => {
          this.authError = 'Password updated.'
        })
        .catch((error) => {
          this.authError = error
        })
    },
    async switchorganization(newOrg) {
      let switchOrg = this.details
      switchOrg.organization =
        this.details.organizations[newOrg].organizationname
      switchOrg.organizationid = newOrg
      try {
        await setDoc(doc(db, 'users', switchOrg.email), switchOrg)
        window.location.reload()
      } catch (error) {
        $q.notify({
          message: 'error: ' + error,
          color: 'warning',
        })
      }
    },
    dev() {
      return window.location.hostname === 'localhost'
    },
    async savePrimaryColor(newcolor) {
      this.organization.primarycolor = newcolor
      try {
        let docref = doc(db, 'organizations', this.organizationid)
        await updateDoc(docref, {
          primarycolor: newcolor,
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async addNotificationAddress(address) {
      // console.log('add:', address)
      if ('notifyonrequest' in this.organization === false) {
        this.organization.notifyonrequest = []
      }
      if (this.organization.notifyonrequest.includes(address) === false) {
        // console.log('not there. Adding.')
        this.organization.notifyonrequest.push(address)
        try {
          let docref = doc(db, 'organizations', this.organizationid)
          await updateDoc(docref, {
            notifyonrequest: this.organization.notifyonrequest,
          })
        } catch (error) {
          console.log('error: ', error)
        }
      } else {
        console.log('already there.')
      }
    },
    async removeNotificationAddress(address) {
      console.log('remove:', address)
      this.organization.notifyonrequest.pop(address)
      try {
        let docref = doc(db, 'organizations', this.organizationid)
        await updateDoc(docref, {
          notifyonrequest: this.organization.notifyonrequest,
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async backupoFullOrganization(orgid) {
      let fullbackupdata = {
        backupdate: date.formatDate(Date.now(), 'YYYY-MM-DD HH:mm:ss'),
        activeOrganizationId: this.organization.id,
        activeOrganizationName: this.organization.name,
        user:
          this.user.displayName +
          ' - ' +
          this.user.email +
          ' - ' +
          this.user.uid,
      }
      this.fullBackupStatus = 'Beginning Full Backup'
      try {
        this.fullBackupStatus = 'Beginning Organization backup'
        let organizationSnapshot = await getDoc(doc(db, 'organizations', orgid))
        let orgdata = organizationSnapshot.data()
        fullbackupdata.orgdata = orgdata
        this.fullBackupStatus = 'Organization backup complete.'
        this.fullBackupProgress = 0.1

        this.fullBackupStatus = 'Beginning Daily Log backup'
        let dailylogSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'dailylog')
        )
        let dailylogdata = {}
        dailylogSnapshot.forEach((doc) => {
          dailylogdata[doc.id] = doc.data()
        })
        fullbackupdata.dailylogdata = dailylogdata
        this.fullBackupStatus = 'Daily Log backup complete.'
        this.fullBackupProgress = 0.3

        this.fullBackupStatus = 'Beginning Contacts backup'
        let contactsSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'contacts')
        )
        let contactsdata = {}
        contactsSnapshot.forEach((doc) => {
          contactsdata[doc.id] = doc.data()
        })
        fullbackupdata.contactsdata = contactsdata
        this.fullBackupStatus = 'Contacts backup complete.'
        this.fullBackupProgress = 0.4

        this.fullBackupStatus = 'Beginning Qualifications backup'
        let qualificationsSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'qualifications')
        )
        let qualificationsdata = {}
        qualificationsSnapshot.forEach((doc) => {
          qualificationsdata[doc.id] = doc.data()
        })
        fullbackupdata.qualificationsdata = qualificationsdata
        this.fullBackupStatus = 'Qualifications backup complete.'
        this.fullBackupProgress = 0.5

        this.fullBackupStatus = 'Beginning Resources backup'
        let resourcesSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'resources')
        )
        let resourcesdata = {}
        resourcesSnapshot.forEach((doc) => {
          resourcesdata[doc.id] = doc.data()
        })
        fullbackupdata.resourcesdata = resourcesdata
        this.fullBackupStatus = 'Resources backup complete.'
        this.fullBackupProgress = 0.6

        this.fullBackupStatus = 'Beginning Requests backup'
        let requestsSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'requests')
        )
        let requestsdata = {}
        requestsSnapshot.forEach((doc) => {
          requestsdata[doc.id] = doc.data()
        })
        fullbackupdata.requestsdata = requestsdata
        this.fullBackupStatus = 'Requests backup complete.'
        this.fullBackupProgress = 0.7

        this.fullBackupStatus = 'Beginning Events backup'
        let eventsSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'events')
        )
        let eventsdata = {}
        eventsSnapshot.forEach((doc) => {
          eventsdata[doc.id] = doc.data()
        })
        fullbackupdata.eventsdata = eventsdata
        this.fullBackupStatus = 'Events backup complete.'
        this.fullBackupProgress = 0.8

        this.fullBackupStatus = 'Beginning Changelog backup'
        let changelogSnapshot = await getDocs(
          collection(db, 'organizations', orgid, 'changelog')
        )
        let changelogdata = {}
        changelogSnapshot.forEach((snap) => {
          changelogdata[snap.id] = snap.data()
        })
        fullbackupdata.changelogdata = changelogdata
        this.fullBackupStatus = 'Changelog backup complete.'
        this.fullBackupProgress = 1
      } catch (error) {
        console.log('Full Backup error:', error)
      }
      this.fullBackupStatus = 'Full Backup complete.'
      console.log('Full Backup: ', fullbackupdata)
      return fullbackupdata
    },
    async restoreFullOrganization(jsonToRestore) {
      this.fullRestoreProgress = 0
      this.fullRestoreStatus = 'Beginning Restore...'
      try {
        if (jsonToRestore.activeOrganizationId === this.organizationid) {
          console.log('Org match. Restore org data.')
          this.fullRestoreStatus = 'Restoring Organization data...'
          await setDoc(
            doc(db, 'organizations', this.organizationid),
            jsonToRestore.orgdata
          )
          this.fullRestoreStatus = 'Organization data restored'
          this.fullRestoreProgress = 0.05
        } else {
          console.log('No org match. Do not restore Org data!')
        }

        this.fullRestoreStatus = 'Restoring dailylog data...'
        for (const record in jsonToRestore.dailylogdata) {
          await setDoc(
            doc(
              collection(db, 'organizations', this.organizationid, 'dailylog'),
              jsonToRestore.dailylogdata[record].id
            ),
            jsonToRestore.dailylogdata[record]
          )
        }
        this.fullRestoreStatus = 'dailylog data restored'
        this.fullRestoreProgress = 0.1

        this.fullRestoreStatus = 'Restoring contact data...'
        for (const record in jsonToRestore.contactsdata) {
          await setDoc(
            doc(
              collection(db, 'organizations', this.organizationid, 'contacts'),
              jsonToRestore.contactsdata[record].id
            ),
            jsonToRestore.contactsdata[record]
          )
        }
        this.fullRestoreStatus = 'contact data restored'
        this.fullRestoreProgress = 0.2

        this.fullRestoreStatus = 'Restoring qualifications data...'
        for (const record in jsonToRestore.qualificationsdata) {
          await setDoc(
            doc(
              collection(
                db,
                'organizations',
                this.organizationid,
                'qualifications'
              ),
              jsonToRestore.qualificationsdata[record].id
            ),
            jsonToRestore.qualificationsdata[record]
          )
        }
        this.fullRestoreStatus = 'qualifications data restored'
        this.fullRestoreProgress = 0.3

        this.fullRestoreStatus = 'Restoring resources data...'
        for (const record in jsonToRestore.resourcesdata) {
          await setDoc(
            doc(
              collection(db, 'organizations', this.organizationid, 'resources'),
              jsonToRestore.resourcesdata[record].id
            ),
            jsonToRestore.resourcesdata[record]
          )
        }
        this.fullRestoreStatus = 'resources data restored'
        this.fullRestoreProgress = 0.5

        this.fullRestoreStatus = 'Restoring requests data...'
        for (const record in jsonToRestore.requestsdata) {
          await setDoc(
            doc(
              collection(db, 'organizations', this.organizationid, 'requests'),
              jsonToRestore.requestsdata[record].id
            ),
            jsonToRestore.requestsdata[record]
          )
        }
        this.fullRestoreStatus = 'requests data restored'
        this.fullRestoreProgress = 0.65

        this.fullRestoreStatus = 'Restoring events data...'
        for (const record in jsonToRestore.eventsdata) {
          await setDoc(
            doc(
              collection(db, 'organizations', this.organizationid, 'events'),
              jsonToRestore.eventsdata[record].id
            ),
            jsonToRestore.eventsdata[record]
          )
        }
        this.fullRestoreStatus = 'events data restored'
        this.fullRestoreProgress = 0.8

        this.fullRestoreStatus = 'Restoring changelog data...'
        for (const record in jsonToRestore.changelogdata) {
          if (
            'id' in jsonToRestore.changelogdata[record] &&
            jsonToRestore.changelogdata[record].id
          ) {
            await setDoc(
              doc(
                collection(
                  db,
                  'organizations',
                  this.organizationid,
                  'changelog'
                ),
                jsonToRestore.changelogdata[record].id
              ),
              jsonToRestore.changelogdata[record]
            )
          }
        }
        this.fullRestoreStatus = 'changelog data restored'
        this.fullRestoreProgress = 1
      } catch (error) {
        console.log(error)
        console.trace()
      }
      this.fullRestoreStatus = 'Restore complete!'
    },
  },
})
