const routes = [
  {
    path: '/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', name: '/', component: () => import('pages/AuthPage.vue') },
    ],
  },

  // {
  //   path: '/Dashboard',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Dashboard',
  //       component: () => import('pages/DashboardPage.vue'),
  //     },
  //   ],
  // },
  {
    path: '/Dailylog',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'DailyLog',
        component: () => import('pages/DailyLogPage.vue'),
      },
    ],
  },
  {
    path: '/Contacts',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Contacts',
        component: () => import('pages/ContactsPage.vue'),
      },
    ],
  },
  {
    path: '/Qualifications',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Qualifications',
        component: () => import('pages/QualificationsPage.vue'),
      },
    ],
  },
  {
    path: '/Resources',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Resources',
        component: () => import('pages/ResourcesPage.vue'),
      },
    ],
  },
  {
    path: '/Requests',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Requests',
        component: () => import('pages/RequestsPage.vue'),
      },
    ],
  },
  {
    path: '/Events',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Events',
        component: () => import('pages/EventsPage.vue'),
      },
    ],
  },
  {
    path: '/Admin',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import('src/pages/AdminPage.vue'),
      },
    ],
  },
  {
    path: '/Organizations',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Organizations',
        component: () => import('pages/OrganizationsPage.vue'),
      },
    ],
  },
  {
    path: '/AllContacts',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'AllContacts',
        component: () => import('pages/AllContactsPage.vue'),
      },
    ],
  },
  {
    path: '/AllResources',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'AllResources',
        component: () => import('pages/AllResourcesPage.vue'),
      },
    ],
  },
  {
    path: '/Help',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Help',
        component: () => import('pages/HelpPage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

export default routes
