import { store } from 'quasar/wrappers'
import { createPinia } from 'pinia'
// import { watch, computed } from 'vue'
import LogRocket from 'logrocket'
// import { deepCompare } from '/src/utility/objectDiff'

// LogRocket.init('auqfci/rtt3')

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store((/* { ssrContext } */) => {
  const pinia = createPinia()

  // watch(
  //   pinia.state,
  //   (newState, oldState) => {
  //     console.log('diff: ', deepCompare(newState, oldState))
  //     // LogRocket.log('[Pinia]', findDeepDifferences(newState, oldState))
  //   },
  //   { deep: true }
  // )

  // You can add Pinia plugins here
  // pinia.use(SomePiniaPlugin)

  return pinia
})
