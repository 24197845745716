import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCkploNnDrdO9w9TXwPzdYnGgFeWIwgfQQ',
  authDomain: 'resource-tracking-tool.firebaseapp.com',
  databaseURL: 'https://resource-tracking-tool.firebaseio.com',
  projectId: 'resource-tracking-tool',
  storageBucket: 'resource-tracking-tool.appspot.com',
  messagingSenderId: '358297465841',
  appId: '1:358297465841:web:46b1b9ab200b869818ca50',
  measurementId: 'G-97DMFPPKEG',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

export { db, auth }
